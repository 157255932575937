const AUTH_VERSION = 'v1';
const API_VERSION = 'v1';

let BASE_URL_AUDIO = 'https://api.totoopen.by/audio/';
let BASE_URL_AUTH = 'http://totoTotoAuthApi/public/api/' + AUTH_VERSION + '/master';
let BASE_URL_API = 'http://totoTotoMainApi/public/api/' + API_VERSION + '/master';;

if(process.env.NODE_ENV === 'production'){
    BASE_URL_AUTH = 'https://auth.totoopen.by/public/api/' + AUTH_VERSION + '/master';;
    BASE_URL_API = 'https://api.totoopen.by/public/api/' + API_VERSION + '/master';;
}


export { BASE_URL_AUTH, BASE_URL_API, BASE_URL_AUDIO};